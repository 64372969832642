import axios from 'axios';
import {Token} from "./Token";

// Function to save tokens to localStorage
function saveTokens(accessTokenJson: string, refreshTokenJson: string) {
    localStorage.setItem('accessToken', JSON.stringify(accessTokenJson));
    localStorage.setItem('refreshToken', JSON.stringify(refreshTokenJson));
}

export async function fetchTokens(email: string, password: string) {
    const url = 'https://openapi.ilinykh.org/api/public/v1/auth/login';
    const data = {
        email: email,
        password: password
    };

    return axios.post(url, data)
        .then((response) => {
            // Save tokens to localStorage
            const {accessToken, refreshToken} = response.data;
            saveTokens(accessToken, refreshToken);
        })
        .catch((error) => {
            console.warn('Login failed:', error.response ? error.response.data : error.message);
            throw error;
        })
}

export async function refreshTokens() {
    const url = 'https://openapi.ilinykh.org/api/public/v1/auth/refresh';
    const refreshTokenObj = localStorage.getItem('refreshToken');
    let refreshToken;
    if (refreshTokenObj) {
        refreshToken = JSON.parse(refreshTokenObj).token;
    }

    const data = {
        'refreshToken': refreshToken
    };

    axios.post(url, data)
        .then((response) => {
            const {accessToken, refreshToken} = response.data;
            saveTokens(accessToken, refreshToken);
            console.info('Token was refreshed')
        })
        .catch((error) => {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            console.warn('Refresh failed:', error.response ? error.response.data : error.message);
        })
}

export function isAuthenticated() {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
        return false; // No refresh token means the user is not authenticated
    }

    const token = JSON.parse(refreshToken).token

    return new Token(token).isValid();
}