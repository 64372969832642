import {TreeNode} from "./TreeNode";

import yaml from "js-yaml";

export function stringToTree(str: string) {
    let jsonObject = undefined;

    try {
        jsonObject = JSON.parse(str);
    } catch (e) {
        return new TreeNode(
            "root",
            str,
            "string",
            true,
            ""
        )
    }

    return jsonToTree(jsonObject);
}

function jsonToTree(json: any, key = 'root') {
    if (typeof json === 'object' && json !== null) {
        const node = new TreeNode(key, null, Array.isArray(json) ? 'array' : 'object');

        for (const [childKey, childValue] of Object.entries(json)) {
            const childNode = jsonToTree(childValue, childKey);
            node.children.push(childNode);
        }

        return node;
    } else {
        // Primitive value (string, number, boolean, null)
        return new TreeNode(key, json, 'primitive', true, "");
    }
}

interface SchemaProps {
    type: string;
    description: string;
    properties: any;
    required: string[];
}

export function treeToOpenApi(node: TreeNode): any {

    if (node.type === 'object') {
        const requiredChildren = node.children.filter(child => child.required === true).map(child => child.key);

        const schema: SchemaProps = {
            type: 'object',
            description: node.description,
            properties: {},
            required: requiredChildren,
        };

        node.children.forEach(child => {
            schema.properties[child.key] = treeToOpenApi(child);
        })

        return schema;
    }

    if (node.type === 'array') {
        if (node.children[0].type === 'object') {
            const first = treeToOpenApi(node.children[0]);

            return {
                type: 'array',
                items: {
                    type: 'object',
                    properties: first.properties,
                },
                description: "",
            }
        } else {
            return {
                type: 'array',
                description: node.description,
                items: {
                    type: typeof node.children[0].value
                },
                example: node.children.map(child => child.value)
            }
        }
    }

    return {
        type: typeof node.value,
        example: node.value,
        description: node.description,
    };
}