import React from "react";
import CodeMirror from "@uiw/react-codemirror";
import { StreamLanguage } from '@codemirror/language';
import { yaml } from '@codemirror/legacy-modes/mode/yaml';
import "./OpenApi.css"

interface OpenApiProps {
    openApi: any;
    onChange: (a: any) => any;
}

const OpenApi = ({ openApi, onChange }: OpenApiProps) => {
    return (
        <div className={"openapi-wrapper"}>
            <CodeMirror
                height="100%"
                width="100%"
                value={openApi}
                onChange={(e: any) => onChange(e)}
                extensions={[StreamLanguage.define(yaml)]}
                placeholder={"Openapi will be here"}
            />
        </div>
    );
};

export default OpenApi;