import axios from "axios";
import RegistrationRequest from "./RegistrationRequest";

export const register = async (req: RegistrationRequest) => {
    const url = 'https://openapi.ilinykh.org/api/public/v1/registration';

    const config = {
        headers: {'Content-Type': 'application/json'}
    }

    try {
        return await axios.post(url, req, config)
    } catch (error: any) {
        throw error.response.data.message;
    }
}