export class PathModel {
    value: string;

    constructor(value: string) {
        this.value = value;
    }

    #validators = [
        (value: string) => value.trim().length > 0 ? undefined : "Path is required.",
        (value: string) => value.startsWith('/') ? undefined : "Path must start with a '/'.",
        (value: string) => !/[^a-zA-Z0-9/_-{}]/.test(value) ? undefined : "Only alphanumeric, '/', '_','{','}' and '-' are allowed.",
    ]

    isValid(): boolean {
        for (const validator of this.#validators) {
            const errorMessage = validator(this.value);

            if (errorMessage) {
                return false;
            }
        }

        return true;
    }

    errorMessage(): string | undefined {
        for (const validator of this.#validators) {
            const errorMessage = validator(this.value);

            if (errorMessage) {
                return errorMessage;
            }
        }

        return undefined;
    }
}