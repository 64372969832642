interface YamlSchemaProps {
    type: string
}

interface YamlProps {
    in: string;
    name: string;
    required: boolean;
    description: string;
    example?: string;
    schema: YamlSchemaProps;
}

export class ParameterModel {
    nameErrorMessage = "";
    exampleErrorMessage = "";

    constructor(
        public id: any,
        public name: string,
        public required: boolean,
        public description: string,
        public type: string,
        public example?: string,
    ) {}

    validateName() {
        const validators = [
            (value: string) => value.trim().length > 0 ? undefined : "Name is required.",
            (value: string) => !/[^a-zA-Z0-9/_\-{}]/.test(value) ? undefined : "Only alphanumeric, '/', '_','{','}' and '-' are allowed.",
        ]

        for (const validator of validators) {
            const errorMessage = validator(this.name);

            if (errorMessage) {
                this.nameErrorMessage = errorMessage;
                return false;
            }
        }

        return true;
    }

    validateExample() {
        if (this.example === undefined || this.example === null) {
            return true;
        }
        const validators = [
            (value: string) => value.trim().length > 0 ? undefined : "Example is required.",
        ]

        for (const validator of validators) {
            console.trace(this.example);
            const errorMessage = validator(this.example.toString());

            if (errorMessage) {
                this.exampleErrorMessage = errorMessage;
                return false;
            }
        }

        return true;
    }

    isValid(): boolean {
        return this.validateName() && this.validateExample();
    }

    toYaml(): YamlProps {
        const schemaType = this.example ? typeof this.example : "string";
        const example = this.example !== undefined ? this.example : undefined;

        return {
            in: this.type,
            name: this.name,
            required: this.required,
            description: this.description,
            example: example,
            schema: {
                type: schemaType
            }
        };
    }
}
