import React from 'react';
import './MainPage.css';
import openapiExample from '../resources/openapi_example.png';
import {useNavigate} from "react-router-dom";
import Button from "../components/button/Button";



const MainPage = () => {
    const navigate = useNavigate();

    const handleSignIn = () => {
        navigate('/register')
    }

    return (
        <div className="white-container">
            <div className="white-content">
                <div className="white-text">
                    <h1 className="white-title">
                        Create <span className="blue-highlight">OpenAPI Documentation</span> Instantly
                    </h1>
                    <p className="white-subtitle">
                        Simplify API documentation for analysts, architects, and developers with our intuitive
                        form-based tool. No coding required!
                    </p>

                    <div className="features-section">
                        <div className="feature-cards">
                            <div className="card">
                                <h3>🚀 Save Time</h3>
                                <p className={"white-subtitle"}>No more manual YAML/JSON editing. Fill the form and
                                    generate docs in seconds.</p>
                            </div>
                            <div className="card">
                                <h3>📝 Formatting/validation json.</h3>
                                <p className={"white-subtitle"}>Spend your time solving business problems instead of
                                    writing YAML or JSON. Our tool handles the technical details for you.</p>
                            </div>
                            <div className="card">
                                <h3>✅ Reduce Errors</h3>
                                <p className={"white-subtitle"}>Automated generation never forget about necessary fields
                                    and structure.</p>
                            </div>
                        </div>
                    </div>

                    <Button text="Get Started for Free" color="blue" onClick={handleSignIn}/>
                </div>

                <div className="white-image-container">
                    <img src={openapiExample} alt="OpenAPI Generation example" className="white-image"/>
                </div>
            </div>
        </div>
    );
};

export default MainPage;
