import React from 'react';
import Button from '../../../components/button/Button';
import {ParameterModel} from "../models/ParameterModel";
import Parameter from "./Parameter";

interface ParameterListProps {
    title: string;
    parameters: any[],
    setParameters: (a: any) => any;
    type: string;
}

function ParameterList ({ title, parameters, setParameters, type }: ParameterListProps) {
    const handleAddParameter = () => {
        const newParameter = new ParameterModel(Date.now(), '', false, '', type, '');
        setParameters([...parameters, newParameter]);
    };

    const handleRemoveParameter = (id: any) => {
        setParameters(parameters.filter(param => param.id !== id));
    };

    return (
        <div className="vertical-block">
            <div className="line"><h3>{title}</h3></div>
            <div>
                {parameters.map((param) => (
                    <div key={param.id}>
                        <Parameter
                            model={param}
                        />
                        <div className="line">
                            <Button color="red" text="Remove" onClick={() => handleRemoveParameter(param.id)} />
                        </div>
                    </div>
                ))}
            </div>
            <div>
                <Button color="green" text={`Add ${title}`} onClick={handleAddParameter} />
            </div>
        </div>
    );
};

export default ParameterList;