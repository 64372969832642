import { stringToTree, treeToOpenApi } from "../service/generate/JsonToOpenApi";
import yaml from "js-yaml";

/**
 * Common model for all json-schema manipulations.
 */
export class BodyModel {
    constructor(public json: string, public schema?: string) {}

    getSchema(): string | undefined {
        if (!this.json || this.json.length === 0 || this.json.match(/^ *$/)) {
            this.schema = undefined;
        } else {
            const openapinew = treeToOpenApi(stringToTree(this.json));
            const yamlObj = {
                description: "",
                content: {
                    'application/json': {
                        schema: openapinew
                    }
                }
            };

            this.schema = yaml.dump(yamlObj, { lineWidth: -1 });
        }
        return this.schema;
    }
}