export class TopupResponse {
    orderId: any;
    status: string;
    tokensRequested: number;
    pricePerToken: { amount: number, currency: string };
    priceTotal: { amount: number, currency: string };
    url: string;

    constructor(order: any) {
        this.orderId = order.order;
        this.status = order.status;
        this.tokensRequested = order.tokensRequested;
        this.pricePerToken = order.pricePerToken;
        this.priceTotal = order.priceTotal;
        this.url = order.url;
    }

    // Method to update the status of the order
    updateStatus(newStatus: string): void {
        this.status = newStatus;
    }

    // Method to get the current status of the order
    getStatus(): string {
        return this.status;
    }

    // Method to get the total price of the order
    getTotalPrice(): string {
        return `${this.priceTotal.amount} ${this.priceTotal.currency}`;
    }

    // Method to get the URL for payment
    getPaymentUrl(): string {
        return this.url;
    }

    // Method to convert the object to a JSON string
    toJSON(): string {
        return JSON.stringify(this, null, 2);
    }
}