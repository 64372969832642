import React, {useState} from "react";
import "./ResponseBlock.css";
import Button from "../../../components/button/Button";
import InputWithError from "../../../components/input/InputWithError";
import RequestResponseBody from "./RequestResponseBody";
import {ResponseModel} from "../../../domain/ResponseModel";
import {BodyModel} from "../../../domain/BodyModel";

interface ResponseBlockProps {
    model: any;
    onRemove: (a: any) => any;
    onUpdate: (responseId: any, newResponseModel: ResponseModel) => void;
}

/**
 *
 * @param model model from ResponseList
 * @param onRemove action for remove
 * @param onUpdate action for update model from ResponseList
 * @constructor
 */
function ResponseBlock ({model, onRemove, onUpdate}: ResponseBlockProps) {
    const [code, setCode] = useState<string>(model.responseModel.statusCode);
    const [bodyModel, setBodyModel] = useState(model.responseModel.bodyModel);
    const [responseId] = useState(model.id)

    const validateCode = (value: any) => {
        const valid = value === '' || (!isNaN(parseFloat(value)) && isFinite(value) && parseFloat(value) > 0);
        return valid && value !== '';
    };

    const handleCodeChange = (value: string) => {
        const fieldValid = value === '' || (!isNaN(parseFloat(value)) && isFinite(parseFloat(value)) && parseFloat(value) > 0);
        if (!fieldValid) return;

        setCode(value);
        if (value !== '') {
            updateResponseModel(parseInt(value), bodyModel);
        }
    };

    const updateBodyModel = (updatedBodyModel: BodyModel) => {
        setBodyModel(updatedBodyModel);
        console.trace("Update BodyModel in ResponseBlock:")
        console.trace(updatedBodyModel);

        if (code !== null) {
            updateResponseModel(parseInt(code), updatedBodyModel);
        }
    }

    const updateResponseModel = (newCode: number, newBodyModel: BodyModel) => {
        const newResponseModel = new ResponseModel(newBodyModel, newCode)
        console.trace("Update ResponseModel: ");
        console.trace(newResponseModel);

        onUpdate(responseId, newResponseModel);
    }

    return (
        <div className="response-block">
            <div className="vertical-block">
                <div className="response-block-meta-line">
                    <div>
                        <InputWithError
                            className="resp-status-code"
                            errorText="Must not be empty"
                            showError={!validateCode(code)}
                            value={code}
                            placeholder="Status code"
                            onChange={(e) => handleCodeChange(e)}
                        />
                    </div>
                    <select
                        disabled={true}
                        value="application/json"
                        className="content-type"
                    >
                        <option value="application/json">application/json</option>
                    </select>
                </div>
                <RequestResponseBody
                    bodyModel={bodyModel}
                    onUpdate={updateBodyModel}
                />
            </div>
            <div className="vertical-block">
                <div>
                    <Button color="red" text="Remove" onClick={() => onRemove(responseId)}/>
                </div>
            </div>
        </div>
    );
};

export default ResponseBlock;
