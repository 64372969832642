import React from 'react';
import './InputWithError.css';

interface InputWithErrorProps {
    type?: string;
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    errorText?: string;
    showError?: boolean;
    className?: string;
}


function InputWithError ({ type = "text", value, onChange, placeholder, errorText, showError, className } : InputWithErrorProps ) {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    }
    return (
        <div className="input-with-error">
            <input
                type={type}
                value={value}
                onChange={handleChange}
                className={`input ${showError ? 'error' : ''}${className ? ` ${className}` : ''}`}
                placeholder={placeholder}
            />
            <div className="error-message">{errorText}</div>
        </div>
    );
};

export default InputWithError;
