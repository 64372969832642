import React, {useState} from 'react';
import {fetchTokens} from "../../service/authService";
import {useNavigate} from "react-router-dom";
import './LoginPage.css';

interface LoginPageProps {
    onAuthChange: (a: boolean) => any;
}
function LoginPage ({onAuthChange}: LoginPageProps) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")

    const handleLogin = async (e: any) => {
        setShowError(false);
        e.preventDefault()
        try {
            await fetchTokens(email, password); // Ensure this resolves correctly
            navigate('/generator'); // Navigate only after successful login
            onAuthChange(true);
            setShowError(false);
        } catch (error: any) {
            // console.error('Error during login:', error);
            if (error.status === 409) {
                setErrorMessage("Verify email first.")
            }

            if ([400, 401].includes(error.status)) {
                setErrorMessage("Incorrect email or password.")
            }
            setShowError(true);
            onAuthChange(false);
        }
    }

    return (
        <div className="login-container">
            <form className="login-card" onSubmit={handleLogin}>
                <h2 className='login-card-item'>Welcome Back</h2>
                <input
                    id="email"
                    type="email"
                    placeholder="Email"
                    className="login-input login-card-item"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    id="password"
                    type="password"
                    placeholder="Password"
                    className="login-input login-card-item"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                {showError && <label className="login-error-message">{errorMessage}</label>}
                <button type="submit" className="login-button login-card-item">Login</button>
            </form>
        </div>
    );
};

export default LoginPage;