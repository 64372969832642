import {apiClient} from "../../service/apiClient";
import {getAccessToken} from "../../service/tokenService";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import "./ProfilePage.css"
import Button from "../../components/button/Button";

function ProfilePage() {
    const navigate = useNavigate();
    let profile = JSON.parse(localStorage.getItem("profile") as string) || {};

    const [customer, setCustomer] = useState({
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        balance: profile.balance,
    });
    useEffect(() => {
        const url = 'https://openapi.ilinykh.org/api/v1/customer';
        const fetchClient = async () => {
            const { data } = await apiClient.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + getAccessToken(),
                }
            });
            localStorage.setItem("profile", JSON.stringify(data))
            setCustomer(data)
        };
        fetchClient();
    }, []);

    const handleTopUpClick = () => {
        navigate("/topup");
    };

    return (
        <div className="container">
            <div className="profile-line">
                <label>First name: </label>
                <span>{customer.firstName}</span>
            </div>
            <div className="profile-line">
                <label>Last name: </label>
                <span>{customer.lastName}</span>
            </div>
            <div className="profile-line">
                <label>Email: </label>
                <span>{customer.email}</span>
            </div>
            <div className="profile-line">
                <label>Balance: </label>
                <span>{customer.balance}</span>
                <Button color="blue" text="Top up balance" className={"topup-btn"} onClick={handleTopUpClick}/>
            </div>
        </div>
    );
};

export default ProfilePage;