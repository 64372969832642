import "./PricingPage.css"

const PricingPage = () => {

    return (
        <div className={"container"}>
            <div className={"price-section"}>
                <h2>Individual Plan</h2>

                <p>
                    We offer you a convenient and transparent approach to paying for our services. You purchase
                    generation
                    packages starting from the minimum package of 100 generations. This allows you to manage your
                    resources
                    efficiently and use them productively.
                </p>

                <ul>
                    <li><strong>Payment:</strong> Only for successful openAPI generation.</li>
                </ul>

                <p>
                    You can always monitor the number of remaining generations and replenish your balance as needed. We
                    strive to provide you with a flexible payment system so that you can focus on your work without
                    additional financial concerns.
                </p>

                <table className={"price-table"}>
                    <thead>
                    <tr>
                        <th>Generations</th>
                        <th>Price</th>
                        <th>Price per one</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>100</td>
                        <td>$10</td>
                        <td>$0.1</td>
                    </tr>
                    <tr>
                        <td>5000</td>
                        <td>$50</td>
                        <td>$0.01</td>
                    </tr>
                    <tr>
                        <td>30000</td>
                        <td>$150</td>
                        <td>$0.005</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div className={"price-section"}>
                <h2>B2B Plan</h2>

                <p>We understand how crucial stable operations and growth are for your business. That's why we offer
                    unique
                    conditions for corporate clients:</p>

                <div className="price-features">
                    <div className="price-feature">
                        <h3>Unlimited Generations for All Your Employees</h3>
                        <p>There are no limits on the number of generations. Your employees will be able to work as much
                            as
                            they need, without any barriers.</p>
                    </div>

                    <div className="price-feature">
                        <h3>Personal Technical Support</h3>
                        <p>Your team will receive priority support from our team of experts. Any questions will be
                            addressed
                            promptly and professionally.</p>
                    </div>

                    <div className="price-feature">
                        <h3>Priority Improvements and Fixes</h3>
                        <p>All your requests for changes and updates will be processed first. We guarantee quick
                            response
                            times and implementation of necessary improvements.</p>
                    </div>
                </div>

                <p>Start collaborating with us today and get all the benefits of our service for your business!</p>

                <p>Please contact <a href="mailto:support@ilinykh.org">support@ilinykh.org</a> for a personal plan.
                </p>
            </div>
        </div>
    );
};
export default PricingPage;