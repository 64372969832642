import 'js-yaml';

export function findPath(yamlContent: any) {
    if (!yamlContent && !yamlContent.paths) {
        return null;
    }

    const paths = Object.keys(yamlContent.paths);
    if (paths.length > 0) {
        return paths[0]
    }

    return null;
}

export function findMethod(yamlContent: any, path: any) {
    return Object.keys(yamlContent.paths[path])[0];
}

export function findSummary(yamlContent: any, path: any, method: any) {
    return yamlContent.paths[path][method].summary;
}

export function parseParams(yamlContent: any, path: any, method: any) {
    return yamlContent.paths[path][method].parameters;
}

export function findRequestBody(yamlContent: any) {

    if (!yamlContent || !yamlContent.content || !yamlContent.content['application/json'] || !yamlContent.content['application/json'].schema) {
        return null;
    }

    const schema = yamlContent.content['application/json'].schema;

    // Handle different schema types
    let requestBody;

    switch (schema.type) {
        case 'object':
            requestBody = parseObjectSchema(schema);
            break;
        case 'array':
            requestBody =  parseArraySchema(schema);
            break;
        case 'string':
        case 'number':
        case 'integer':
        case 'boolean':
            requestBody =  parsePrimitiveSchema(schema);
            break;
        default:
            requestBody = null;
            break;
    }

    if (typeof requestBody === 'object') {
        return JSON.stringify(requestBody, null, 2);
    }
    else {
        return requestBody;
    }
}

interface SchemaProperty {
    type: 'string' | 'number' | 'integer' | 'boolean' | 'object' | 'array';
    example?: string | number | boolean; // Optional example value
    properties?: Record<string, SchemaProperty>; // For nested objects
    items?: SchemaProperty; // For arrays
}

interface Schema {
    properties: Record<string, SchemaProperty>;
}

function parseObjectSchema(schema: Schema): Record<string, unknown> {
    const requestBody: Record<string, unknown> = {};

    for (const [key, value] of Object.entries(schema.properties)) {
        if (value.type === 'string' && value.example !== undefined) {
            requestBody[key] = value.example;
        } else if (value.type === 'number' && value.example !== undefined) {
            requestBody[key] = value.example;
        } else if (value.type === 'integer' && value.example !== undefined) {
            requestBody[key] = value.example;
        } else if (value.type === 'boolean' && value.example !== undefined) {
            requestBody[key] = value.example;
        } else if (value.type === 'object' && value.properties) {
            requestBody[key] = parseObjectSchema(value as Schema);
        } else if (value.type === 'array' && value.items) {
            requestBody[key] = parseArraySchema(value);
        }
    }

    return requestBody;
}

function parseArraySchema(schema: any): any {
    if (!schema.items) {
        return null;
    }

    const exampleArray = [];
    const itemSchema = schema.items;

    const examplesSet = new Set([
        "string",
        "number",
        "integer",
        "boolean",
    ]);

    if (examplesSet.has(itemSchema.type) && schema.example) {
        exampleArray.push(...schema.example);
    } else if (itemSchema.type === 'object' && itemSchema.properties) {
        exampleArray.push(parseObjectSchema(itemSchema));
    } else if (itemSchema.type === 'array' && itemSchema.items) {
        exampleArray.push(parseArraySchema(itemSchema));
    }

    return exampleArray;
}

function parsePrimitiveSchema(schema: any) {
    if (schema.example) {
        return schema.example;
    }
    return null;
}

interface OpenApiParsed {
    path: string;
    method: string;
    summary: string;
    requestBody?: any,
    responses: any[];
    headers: any[];
    pathParams: any[];
    queryParams: any[];
}

export function parseOpenApi(yamlString: any): OpenApiParsed | null {
    const yaml = require('js-yaml');

    const content = yaml.load(yamlString, {});
    const path = findPath(content);
    if (path === null) {
        return null;
    }

    let method: any = undefined;
    let summary = undefined;
    let requestBody = undefined;
    let params: any[] = [];
    let responseCodes: any[] = [];

    if (path !== undefined) {
        method = findMethod(content, path);
    }

    if (path !== undefined && method !== undefined) {
        summary = findSummary(content, path, method);
        requestBody = findRequestBody(content.paths[path][method].requestBody) || undefined;
        params = parseParams(content, path, method) || [];
    }

    if (path !== undefined && method !== undefined && content.paths[path][method].responses !== undefined) {
        responseCodes = Object.keys(content.paths[path][method].responses) || [];
    }

    const headerParams = params.filter((param) => param.in && param.in === 'header')
    const pathParams = params.filter((param) => param.in && param.in === 'path')
    const queryParams = params.filter((param) => param.in && param.in === 'query')

    let responsesYaml = [];
    for (const code of responseCodes) {
        const response = content.paths[path][method].responses[code];
        responsesYaml.push(
            {
                code: code,
                body: findRequestBody(response),
            }
        );
    }

    return {
        path: path,
        method: method,
        summary: summary,
        requestBody: requestBody,
        responses: responsesYaml,
        headers: headerParams,
        pathParams: pathParams,
        queryParams: queryParams,
    };
}