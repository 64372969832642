import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Navigate} from 'react-router-dom';
import RegistrationPage from './pages/registration/RegistrationPage';
import OpenApiGeneratorPage from './pages/generator/OpenApiGeneratorPage';
import TopBar from './components/topbar/TopBar';
import './App.css';
import LoginPage from "./pages/login/LoginPage";
import {isAuthenticated} from "./service/authService";
import React, {useEffect, useState} from "react";
import MainPage from "./pages/MainPage";
import ProfilePage from "./pages/profile/ProfilePage";
import EmailVerificationPage from "./pages/emailverification/EmailVerificationPage";
import Footer from "./components/footer/Footer";
import PricingPage from "./pages/pricing/PricingPage";
import TopupPage from "./pages/topup/TopupPage";

interface RouteProps {
    isRegistered: boolean;
    children: any;
}

function ProtectedRoute({isRegistered, children}: RouteProps) {
    if (!isRegistered) {
        return <Navigate to="/login"/>;
    }
    return children;
}

// Public route component to redirect authenticated users
function PublicRoute ({isRegistered, children}: RouteProps) {
    if (isRegistered) {
        return <Navigate to="/generator"/>;
    }
    return children;
}

function App() {
    const [isAuth, setIsAuth] = useState(isAuthenticated()); // State for auth status

    // Monitor authentication state changes if needed (e.g., token storage)
    useEffect(() => {
        async function checkAuthentication() {
            const authStatus = await isAuthenticated();
            setIsAuth(authStatus);
        }

        checkAuthentication();
    }, []);

    return (
        <Router>
            <TopBar onAuthChange={setIsAuth} isAuth={isAuth}/>
            <div>
                <Routes>
                    <Route path="/" element={
                        <MainPage/>
                    }/>

                    <Route path="/register" element={
                        <PublicRoute isRegistered={isAuth}>
                            <RegistrationPage/>
                        </PublicRoute>
                    }/>

                    <Route path="/generator" element={
                        <ProtectedRoute isRegistered={isAuth}>
                            <OpenApiGeneratorPage/>
                        </ProtectedRoute>
                    }/>

                    <Route path="/login" element={
                        <PublicRoute isRegistered={isAuth}>
                            <LoginPage onAuthChange={setIsAuth}/>
                        </PublicRoute>
                    }/>

                    <Route path="/profile" element={
                        <ProtectedRoute isRegistered={isAuth}>
                            <ProfilePage/>
                        </ProtectedRoute>
                    }/>

                    <Route path="/emailverification/:id" element={<EmailVerificationPage/>}/>
                    <Route path="/pricing" element={<PricingPage/>}/>
                    <Route path={"/topup"} element={
                        <ProtectedRoute isRegistered={isAuth}> <TopupPage/></ProtectedRoute>}/>
                </Routes>
            </div>
            <Footer/>
        </Router>
    );
}

export default App;
