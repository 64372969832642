import axios from 'axios';

export const apiClient = axios.create({
    baseURL: 'https://openapi.ilinykh.org', // Replace with your API base URL
});

apiClient.interceptors.response.use(
    (response) => response, // Pass successful responses
    async (error) => {
        console.log('Interceptor caught error:', error.response?.status);
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                // Retrieve and parse refresh token
                const refreshToken = JSON.parse(<string>localStorage.getItem('refreshToken'));
                if (!refreshToken) {
                    throw new Error('No refresh token found.');
                }

                // Send refresh token request
                const { data } = await axios.post(
                    '/api/public/v1/auth/refresh',
                    { refreshToken: refreshToken.token }, // Adjust to backend format
                    { baseURL: 'https://openapi.ilinykh.org' }
                );

                // Update tokens
                localStorage.setItem('accessToken', JSON.stringify(data.accessToken));
                localStorage.setItem('refreshToken', JSON.stringify(data.refreshToken));

                // Update Authorization headers
                const newAccessToken = data.accessToken.token;
                apiClient.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                // Retry the original request
                return apiClient(originalRequest);
            } catch (refreshError) {
                console.error('Refresh token failed:', refreshError);

                // Clear tokens and redirect to login if necessary
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                window.location.href = '/login'; // Optional: Redirect to login page

                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error);
    }
);
