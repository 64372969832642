import './TopupPage.css';
import Button from "../../components/button/Button";
import {buyGenerations} from "../../service/topup/TopupService";

const TopupPage = () => {

    const handleBuyButton = async (param: any) => {
        const response = await buyGenerations({
            "plan": param
        })

        window.open(response.getPaymentUrl(), '_blank');
    }

    return (
        <div className="container">
            <div className={"line topup-line"}>
                <div className={"topup-plan"}>
                    <h3>100 generations</h3>
                    <h4>$10</h4>
                    <span>$0.1 per one.</span>
                    <p>Ideal for small projects.</p>
                    <div className={"topup-btn-contaner"}>
                        <Button text={"Buy 100"} onClick={() => handleBuyButton("COMMON_100")}/>
                    </div>
                </div>

                <div className={"topup-plan topup-plan-popular"}>
                    <h3>5000 generations</h3>
                    <h4>$50</h4>
                    <span>$0.01 per one.</span>
                    <p>Balance between price and quantity.</p>
                    <div className={"topup-btn-contaner"}>
                        <Button color={"green"} text={"Buy 5000"} onClick={() => handleBuyButton("COMMON_5000")}/>
                    </div>
                </div>

                <div className={"topup-plan"}>
                    <h3>30000 generations</h3>
                    <h4>$150</h4>
                    <span>$0.005 per one.</span>
                    <p>The cheapest price for great ideas!</p>
                    <div className={"topup-btn-contaner"}>
                        <Button text={"Buy 30000"} onClick={() => handleBuyButton("COMMON_30000")}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopupPage;