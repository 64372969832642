import React from 'react';
import './Button.css';

interface ButtonProps {
    type?: "button" | "submit" | "reset";
    text: string;
    onClick?: () => void;
    color?: string;
    className?: string;
    disabled?: boolean;
}

function Button({ type = "button", text, onClick, color = 'blue', className = "", disabled = false }: ButtonProps) {
    return (
        <button
            className={`button button-${color} ${className} button-disabled-${disabled}`}
            onClick={onClick}
            type={type}
            disabled={disabled}
        >
            {text}
        </button>
    );
}

export default Button;