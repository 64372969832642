import React, {useState} from 'react';
import Button from '../../components/button/Button';
import InputWithError from '../../components/input/InputWithError';
import ParameterList from './components/ParameterList';
import ResponseList from './components/ResponseList';
import {PathModel} from './models/PathModel';
import RequestResponseBody from "./components/RequestResponseBody";
import {parseOpenApi} from "./services/OpenApiService";
import OpenApi from "./components/OpenApi";
import {ParameterModel} from "./models/ParameterModel";
import {BodyModel} from "../../domain/BodyModel";
import {ResponseModel} from "../../domain/ResponseModel";
import {generateId} from "../../service/IdGenerator";
import yaml from "js-yaml";

const OpenApiGeneratorPage = () => {
    const [method, setMethod] = useState('POST');
    const [path, setPath] = useState(new PathModel(''));
    const [summary, setSummary] = useState('My awesome method');
    const [requestBodyModel, setRequestBodyModel] = useState(new BodyModel('', ''));

    const [blocks, setBlocks] = useState<ResponseModel[]>([]);
    const [blocksFromYaml, setBlocksFromYaml] = useState([new ResponseModel(new BodyModel('', ''), 200)]);
    const [headers, setHeaders] = useState<ParameterModel[]>([]);
    const [queryParams, setQueryParams] = useState<ParameterModel[]>([]);
    const [pathParams, setPathParams] = useState<ParameterModel[]>([]);
    const [loading, setLoading] = useState(false);
    const [openApi, setOpenApi] = useState('');

    const handlePathChange = (value: string) => {
        setPath(new PathModel(value));
    };

    const updateRequestBody = (value: any) => {
        console.trace("Update requestBodyModel:");
        console.trace(value);
        setRequestBodyModel(value);
    }

    const updateBlocks = (newBlocks: any) => {
        console.trace("Update blocks:");
        console.trace(newBlocks);
        setBlocks(newBlocks);
    }

    const updateBlocksFromYaml = (newBlocks: any) => {
        updateBlocks(newBlocks);
        setBlocksFromYaml(newBlocks);
    }

    const handleGenerateLocally = () => {
        if (headers.filter((header) => !header.isValid()).length > 0 || !path.isValid()) {
            setLoading(false);
            return;
        }

        const responses: any = {}
        blocks
            .forEach((block) => {
                if (block.statusCode === undefined) {
                    return;
                }

                let response = block.bodyModel.getSchema();
                if (response === null || response === undefined) {
                    response = yaml.dump({
                        description: "Empty response"
                    })
                }

                responses[block.statusCode] = yaml.load(response, {})
            })

        const yamlObject = {
            openapi: "3.0.1",
            info: {
                title: "Test",
                version: "v1"
            },
            paths: {
                [path.value]: {
                    [method.toLowerCase()]: {
                        summary: summary,
                        parameters: headers.concat(queryParams).concat(pathParams).map((param) => param.toYaml()),
                        requestBody: yaml.load(requestBodyModel.getSchema()!!, {}),
                        responses: responses,
                    }
                }
            }
        }

        if (requestBodyModel.json.length === 0) {
            delete yamlObject.paths[path.value][method.toLowerCase()].requestBody
        }

        if (!blocks || blocks.length === 0) {
            delete yamlObject.paths[path.value][method.toLowerCase()].responses
        }

        setOpenApi(yaml.dump(yamlObject, {}));
    }

    const changeOpenApi = (value: any) => {
        setOpenApi(value);
    }

    return (
        <div className="container">
            <h1>Easy openAPI generator</h1>
            <div className="line">
                <select value={method} onChange={(e) => setMethod(e.target.value)}
                        className="dropdown line-element">
                    <option value="POST">POST</option>
                    <option value="GET">GET</option>
                    <option value="PUT">PUT</option>
                    <option value="PATCH">PATCH</option>
                    <option value="DELETE">DELETE</option>
                    <option value="OPTION">OPTION</option>
                </select>

                <InputWithError
                    value={path.value}
                    onChange={(e) => handlePathChange(e)}
                    placeholder="/api/v1/example"
                    errorText={path.errorMessage()}
                    showError={!path.isValid()}
                />

                <InputWithError
                    value={summary}
                    onChange={(e) => setSummary(e)}
                    placeholder="Summary"
                />
            </div>

            <ParameterList
                title="Headers"
                parameters={headers}
                setParameters={setHeaders}
                type={"header"}
            />

            <ParameterList
                title="Query parameters"
                parameters={queryParams}
                setParameters={setQueryParams}
                type={"query"}
            />

            <ParameterList
                title="Path parameters"
                parameters={pathParams}
                setParameters={setPathParams}
                type={"path"}
            />

            <div className="line">
                <div className='vertical-block'>
                    <h3>Request Body:</h3>
                    <div className={"line"}>
                        <select
                            disabled={true}
                            value="application/json"
                            className="content-type"
                        >
                            <option value="application/json">application/json</option>
                        </select>
                    </div>
                    <RequestResponseBody
                        bodyModel={requestBodyModel}
                        onUpdate={updateRequestBody}
                    />
                </div>
            </div>

            <h3>Responses:</h3>
            <ResponseList
                setResponses={updateBlocks}
                loadResponses={blocksFromYaml}
            />

            <div className="vertical-block center">
                <div className="line">
                    <Button text="Generate OpenAPI (free)" disabled={loading}
                            onClick={() => {
                                handleGenerateLocally()
                            }}/>
                    <Button
                        text={"Load YAML (free)"}
                        onClick={() => {
                            const parsed = parseOpenApi(openApi)

                            if (parsed === null) {
                                return
                            }

                            console.log(parsed);

                            setPath(new PathModel(parsed.path));
                            setSummary(parsed.summary);
                            setMethod(parsed.method.toUpperCase());
                            updateRequestBody(new BodyModel(parsed.requestBody || '', ''));

                            const responseModels = parsed.responses.map((response) =>
                                new ResponseModel(new BodyModel(response.body, ''), response.code)
                            );
                            updateBlocksFromYaml(responseModels);

                            const newHeaders = parsed.headers.map((param) => new ParameterModel(generateId(), param.name, param.required, param.description, "header", param.example));
                            const newPathParams = parsed.pathParams.map((param) => new ParameterModel(generateId(), param.name, param.required, param.description, "path", param.example));
                            const newQueryParams = parsed.queryParams.map((param) => new ParameterModel(generateId(), param.name, param.required, param.description, "query", param.example));

                            setHeaders(newHeaders);
                            setPathParams(newPathParams);
                            setQueryParams(newQueryParams);
                        }
                        }
                    />
                </div>
            </div>

            <div className="line">
                <OpenApi
                    openApi={openApi}
                    onChange={changeOpenApi}/>
            </div>
        </div>
    );
};
export default OpenApiGeneratorPage;
