import ResponseBlock from "./ResponseBlock";
import Button from "../../../components/button/Button";
import React, {useEffect, useState} from "react";
import {ResponseModel} from "../../../domain/ResponseModel";
import {BodyModel} from "../../../domain/BodyModel";
import {generateId} from "../../../service/IdGenerator";

interface ResponseListProps {
    setResponses: (models: ResponseModel[]) => void;
    loadResponses: ResponseModel[];
}

/**
 *
 * @param responses model ResponseModel
 * @param setResponses action for setting ResponseModel
 * @param loadResponses model loaded from yaml
 */
function ResponseList ({setResponses, loadResponses}: ResponseListProps) {
    const [responsesWithId, setResponsesWithId] = useState<any[]>([]);

    useEffect(() => {
        const newResponses = loadResponses.map((response) => (
            {
                id: generateId(),
                responseModel: response,
            }));

        setResponsesWithId(newResponses)
        setResponses(newResponses.map((response) => response.responseModel));
    }, [loadResponses]);

    const handleAddResponse = () => {
        const newModel = new ResponseModel(new BodyModel('', ''), undefined);
        const id = generateId();
        const newResponse = {
            id: id,
            responseModel: newModel
        };

        console.trace("Add new response with id: " + id);
        setResponsesWithId([...responsesWithId, newResponse]);
        setResponses(responsesWithId.map((response) => response.responseModel));
    };

    const handleRemoveResponse = (id: any) => {
        console.trace("Remove response with id: " + id);
        const updatedResponses = responsesWithId.filter(response => response.id !== id);
        setResponsesWithId(updatedResponses);
        setResponses(updatedResponses);
    }

    const handleUpdateResponse = (responseId: any, newResponseModel: ResponseModel) => {
        console.trace("Update response with id: " + responseId);
        console.trace(newResponseModel);

        const newResponses = responsesWithId.map((response) =>
            response.id === responseId ? { ...response, responseModel: newResponseModel } : response
        )

        console.trace("New responses:");
        console.trace(newResponses);

        setResponses(newResponses.map((response) => response.responseModel));
    }

    return (
        <div className='vertical-block'>
            <div>
                {responsesWithId.map((response) => <ResponseBlock
                    key={response.id}
                    model={response}
                    onRemove={handleRemoveResponse}
                    onUpdate={handleUpdateResponse}
                />)}
            </div>
            <div><Button color="green" text="Add response" onClick={handleAddResponse}/></div>
        </div>
    )
}
export default ResponseList;