import './RequestResponseBody.css';
import React, {useEffect, useState} from "react";
import {BodyModel} from "../../../domain/BodyModel";
import {generateId} from "../../../service/IdGenerator";

interface RequestResponseBodyProps {
    bodyModel: BodyModel;
    onUpdate: (a: any) => any
}

function RequestResponseBody ({bodyModel, onUpdate}: RequestResponseBodyProps) {
    const [textAreaId] = useState(generateId());
    const [activeTab, setActiveTab] = useState('example');

    const [schema, setSchema] = useState(bodyModel.schema);
    const [json, setJson] = useState(bodyModel.json);

    // Add this useEffect to update state when bodyModel prop changes
    useEffect(() => {
        setSchema(bodyModel.schema);
        setJson(bodyModel.json);
    }, [bodyModel]);

    const handlePayloadChange = (e: any) => {
        const newValue = e.target.value;
        setJson(newValue);
        onUpdate(new BodyModel(newValue, schema));
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            const textarea = e.target;
            const {selectionStart, selectionEnd} = textarea;
            const value = textarea.value;

            if (selectionStart !== selectionEnd) {
                const startOfFirstLine = value.lastIndexOf('\n', selectionStart - 1) + 1;
                const endOfLastLine = value.indexOf('\n', selectionEnd);
                const endOfSelection = endOfLastLine === -1 ? value.length : endOfLastLine;

                const selectedText = value.substring(startOfFirstLine, endOfSelection);
                const indentedText = selectedText
                    .split('\n')
                    .map((line: any) => `\t${line}`)
                    .join('\n');

                try {
                    textarea.setSelectionRange(startOfFirstLine, endOfSelection);
                    document.execCommand('insertText', false, indentedText);
                } catch {
                    textarea.setRangeText(indentedText, startOfFirstLine, endOfSelection, 'end');
                    textarea.dispatchEvent(new Event('input', {bubbles: true}));
                }

                onUpdate(textarea.value);

                textarea.selectionStart = startOfFirstLine;
                textarea.selectionEnd = startOfFirstLine + indentedText.length;
            } else {
                try {
                    document.execCommand('insertText', false, '\t');
                } catch {
                    textarea.setRangeText('\t', selectionStart, selectionEnd, 'end');
                    textarea.dispatchEvent(new Event('input', {bubbles: true}));
                }

                onUpdate(textarea.value);

                textarea.selectionStart = textarea.selectionEnd = selectionStart + 1;
            }
        }
    };

    const handleFormatJson = () => {
        const bodyValue = json || '';
        if (bodyValue.length === 0) {
            return;
        }
        try {
            const parsed = JSON.parse(bodyValue);
            const formatted = JSON.stringify(parsed, null, 2);
            setJson(formatted);
            onUpdate(new BodyModel(formatted, schema));
        } catch (e) {
            alert('Invalid JSON');
        }
    };

    const setActiveTabName = (name: any) => {
        setActiveTab(name);
    }

    const handleSchemaButton = () => {
        setActiveTabName('schema');
        setSchema(new BodyModel(json, schema).getSchema());
    }

    return (
        <div style={{position: 'relative'}}>
            <div className="req-resp-header">
                <div className="req-resp-tabs">
                    <button
                        className={`req-resp-tab ${activeTab === 'example' ? 'active' : ''}`}
                        onClick={() => setActiveTabName('example')}
                    >
                        Example
                    </button>
                    <button
                        className={`req-resp-tab ${activeTab === 'schema' ? 'active' : ''}`}
                        onClick={handleSchemaButton}
                    >
                        Schema
                    </button>
                </div>
                {activeTab === 'example' && (
                    <button
                        onClick={handleFormatJson}
                        className={`req-resp-format-btn req-resp-format-btn-disabled-${json?.trim().length === 0}`}
                    >
                        Format JSON
                    </button>
                )}
            </div>

            {activeTab === 'example' && (
                <textarea
                    id={textAreaId.toString()}
                    value={json || ''}
                    onChange={handlePayloadChange}
                    onKeyDown={handleKeyDown}
                    className="req-resp-textarea"
                    placeholder='{ "key": "value" }'
                />
            )}

            {activeTab === 'schema' && (
                <textarea
                    id={textAreaId.toString()}
                    value={schema || ''}
                    readOnly
                    className="req-resp-textarea"
                    placeholder='Schema will be displayed here'
                />
            )}
        </div>
    );
};

export default RequestResponseBody;