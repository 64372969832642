import React from 'react';
import "./Footer.css";

function Footer() {
  return (
      <footer className="footer">
          <div className="footer-content">
              <p><a className={"footer-link"} href="mailto:support@ilinykh.org">support@ilinykh.org</a></p>
          </div>
      </footer>
  );
}

export default Footer;