import { jwtDecode } from 'jwt-decode';

export class Token {
    constructor(private token: string) {}

    isValid(): boolean {
        try {
            const decodedToken = jwtDecode<{ exp: number }>(this.token);
            const currentTime = Date.now() / 1000; // Current time in seconds
            return decodedToken.exp > currentTime; // Check if token is expired
        } catch (error) {
            return false; // If token is invalid or decoding fails
        }
    }
}