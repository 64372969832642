import { apiClient } from "../apiClient";
import { getAccessToken } from "../tokenService";
import { TopupResponse } from "./TopupResponse";
import { BuyGenerationsRequest } from "./BuyGenerationsRequest";


export const buyGenerations = async (request: BuyGenerationsRequest) => {
    try {
        const response = await apiClient.post('https://openapi.ilinykh.org/api/v1/customer/balance/topup', request, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + getAccessToken(),
            }
        })

        const {data} = response;

        return new TopupResponse(data)
    } catch (error: any) {
        throw new Error(error.message);
    }
};